import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { ConsentGroup, CookieConsentVersion } from 'constants/consent'
import * as consentSelectors from 'state/consent/selectors'

import { checkConsentGroup } from './utils'

const useIsConsentGroupEnabled = (group: ConsentGroup) => {
  const cookieConsentVersion = useSelector(consentSelectors.getCookieConsentVersion)
  const cookies = useCookie()
  const optanonCookie = cookies.get(cookiesDataByName.OptanonConsent)
  const [isConsentGroupEnabled, setIsConsentGroupEnabled] = useState(
    checkConsentGroup(group, optanonCookie),
  )

  const handleBannerLoaded = useCallback(() => {
    setIsConsentGroupEnabled(checkConsentGroup(group, optanonCookie))
  }, [group, optanonCookie])

  useEffect(() => {
    window.addEventListener('bannerLoaded', handleBannerLoaded)

    return () => {
      window.removeEventListener('bannerLoaded', handleBannerLoaded)
    }
  }, [handleBannerLoaded])

  return useMemo(() => {
    if (cookieConsentVersion === CookieConsentVersion.None) return true

    return isConsentGroupEnabled
  }, [isConsentGroupEnabled, cookieConsentVersion])
}

export default useIsConsentGroupEnabled
