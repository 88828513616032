import { ConsentGroup } from 'constants/consent'

export function checkConsentGroup(group: ConsentGroup, optanonCookie?: string) {
  if (!optanonCookie) return false

  const optanonConsentParams = new URLSearchParams(optanonCookie)
  const optanonConsentGroups = optanonConsentParams.get('groups')?.split(',') || []

  return optanonConsentGroups.includes(group)
}
